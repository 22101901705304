import React from 'react'

export const InstagramIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
    >
        <path
            fill="#fff"
            d="M19.936 5.903a7.23 7.23 0 00-.466-2.457 5.11 5.11 0 00-2.924-2.92 7.348 7.348 0 00-2.428-.465C13.05.013 12.711 0 9.998 0 7.288 0 6.94 0 5.879.061c-.829.017-1.65.175-2.426.466a5.117 5.117 0 00-2.924 2.92 7.256 7.256 0 00-.466 2.422C.014 6.936 0 7.275 0 9.982c0 2.708 0 3.054.062 4.114.017.83.173 1.647.466 2.425a5.11 5.11 0 002.925 2.918 7.323 7.323 0 002.427.499C6.95 19.986 7.288 20 10 20s3.06 0 4.12-.062a7.328 7.328 0 002.428-.465 5.124 5.124 0 002.925-2.92 7.292 7.292 0 00.465-2.424c.048-1.067.062-1.405.062-4.114-.002-2.707-.002-3.05-.064-4.112zm-9.944 9.2a5.129 5.129 0 01-5.134-5.126A5.129 5.129 0 019.992 4.85c1.362 0 2.668.54 3.63 1.501a5.121 5.121 0 010 7.25 5.139 5.139 0 01-3.63 1.5zm5.339-9.246a1.195 1.195 0 11.457-2.3 1.196 1.196 0 01.74 1.104c0 .661-.536 1.196-1.197 1.196z"
        />
    </svg>
)

InstagramIcon.displayName = 'InstagramIcon'
