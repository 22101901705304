import React from 'react'
import styled from 'styled-components'
import { Text } from '@beequip/hexagon'
import { BeequipLogo } from './BeequipLogo'
import { Container } from './Container'

const Wrapper = styled.header`
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
`

const ExtendedContainer = styled(Container)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: ${(props) => props.theme.spacing[1]}px;
    padding-bottom: ${(props) => props.theme.spacing[1]}px;
    background-color: ${(props) => props.theme.white};
`

const ExtendedText = styled(Text)`
    margin-right: ${(props) => props.theme.spacing[1]}px;
`

export const Header = () => (
    <Wrapper>
        <ExtendedContainer>
            <ExtendedText size="s" weight="medium">
                Powered by{' '}
            </ExtendedText>
            <BeequipLogo />
        </ExtendedContainer>
    </Wrapper>
)

Header.displayName = 'Header'
