import React from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { CorporateContactJsonLd, SocialProfileJsonLd } from 'next-seo'
import { Text, Heading } from '@beequip/hexagon'
import { Container } from '@beequip/components/layout/Container'
import { BeequipLogoWhite } from './BeequipLogoWhite'
import { InstagramIcon } from './InstagramIcon'
import { FacebookIcon } from './FacebookIcon'
import { LinkedInIcon } from './LinkedInIcon'

const Wrapper = styled.footer(
    ({ theme }) => css`
        padding: 80px 0;
        background-color: ${theme.colors.buttons.primary};
        color: ${theme.colors.grey[3]};
    `
)

const GridContainer = styled(Container)`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 60px;
`

const List = styled.ul(
    ({ theme }) => css`
        margin: 0;
        padding: 0;
        list-style-type: none;
        line-height: ${theme.spacing[3]}px;
    `
)

const ListItem = styled.li(
    ({ theme }) => css`
        margin-bottom: ${theme.spacing[1]}px;
    `
)

const FooterText = styled(Text)(
    ({ theme }) => css`
        margin-bottom: ${theme.spacing[2]}px;
        line-height: 30px;
        color: ${theme.colors.grey[3]};
    `
)

const FooterHeading = styled(Heading)(
    ({ theme }) => css`
        color: ${theme.colors.white};
    `
)

const A = styled.a(
    ({ theme }) => css`
        text-decoration: none;
        color: ${theme.colors.grey[3]};

        &:hover {
            color: ${theme.colors.white};
            text-decoration: underline;
        }
    `
)

const Logo = styled(BeequipLogoWhite)(
    ({ theme }) => css`
        margin-bottom: ${theme.spacing[1]}px;
    `
)

const IconWrapper = styled.div(
    ({ theme }) => css`
        display: flex;

        > * {
            margin-right: ${theme.spacing[2]}px;
        }
    `
)
export const Footer = () => (
    <Wrapper>
        <GridContainer maxWidth={924}>
            <div>
                <Logo />
                <FooterText>
                    watismijnmachinewaard.nl is onderdeel van{' '}
                    <A href="https://www.beequip.nl">Beequip</A>
                </FooterText>

                <IconWrapper>
                    <Link href="https://www.instagram.com/watismijnmachinewaard.nl/">
                        <a>
                            <InstagramIcon />
                        </a>
                    </Link>

                    <Link href="https://www.facebook.com/watismijnmachinewaard.nl">
                        <a>
                            <FacebookIcon />
                        </a>
                    </Link>
                    <Link href="https://www.linkedin.com/company/watismijnmachinewaard-nl/">
                        <a>
                            <LinkedInIcon />
                        </a>
                    </Link>
                </IconWrapper>
                <SocialProfileJsonLd
                    type="Organization"
                    name="Wat Is Mijn Machine Waard?"
                    url="https://www.watismijnmachinewaard.nl"
                    sameAs={[
                        'https://www.instagram.com/watismijnmachinewaard.nl/',
                        'https://www.facebook.com/watismijnmachinewaard.nl',
                        'https://www.linkedin.com/company/watismijnmachinewaard-nl/',
                    ]}
                />
            </div>
            <div>
                <FooterHeading margin={2} color="white" size={5}>
                    watismijnmachinewaard.nl
                </FooterHeading>
                <List>
                    <ListItem>
                        <Link href="/over-ons" passHref>
                            <A>Over ons</A>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link href="/disclaimer" passHref>
                            <A>Disclaimer</A>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link href="/privacyverklaring" passHref>
                            <A>Privacyverklaring</A>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link href="/cookies" passHref>
                            <A>Cookies</A>
                        </Link>
                    </ListItem>
                </List>
            </div>
            <div>
                <FooterHeading size={5}>Contact</FooterHeading>
                <FooterText>
                    Willemskade 18
                    <br />
                    3016 DL Rotterdam, Nederland
                    <br />
                    <A href="mailto:info@beequip.nl">info@beequip.nl</A>
                    <br />
                    <A href="tel:0103400844">+31 (0)10 340 0844</A>
                </FooterText>
                <CorporateContactJsonLd
                    url="https://www.beequip.nl"
                    logo="https://www.beequip.nl/wp-content/themes/beequip/assets/img/logo-beequip.svg"
                    contactPoint={[
                        {
                            telephone: '+31 (0)10 340 0844',
                            contactType: 'Customer service',
                            areaServed: 'NL',
                            availableLanguage: ['English', 'Dutch'],
                        },
                    ]}
                />
            </div>
        </GridContainer>
    </Wrapper>
)
