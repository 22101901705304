import React from 'react'

export const LinkedInIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
    >
        <path
            fill="#fff"
            d="M4.477 20H.33V6.647h4.147V20zM2.4 4.826C1.075 4.826 0 3.727 0 2.4a2.401 2.401 0 114.803 0c0 1.326-1.076 2.425-2.402 2.425zM19.996 20h-4.138v-6.5c0-1.55-.03-3.536-2.155-3.536-2.156 0-2.487 1.683-2.487 3.424V20H7.074V6.647h3.977v1.821h.058c.554-1.049 1.906-2.156 3.924-2.156 4.196 0 4.967 2.764 4.967 6.353V20h-.004z"
        />
    </svg>
)

LinkedInIcon.displayName = 'LinkedInIcon'
