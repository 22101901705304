import React from 'react'

export const BeequipLogoWhite = ({
    className,
}: React.HTMLAttributes<HTMLOrSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="94"
        height="27"
        fill="none"
        viewBox="0 0 94 27"
        className={className}
    >
        <path
            fill="#fff"
            d="M13.862 7.627L17.424.445l-.861-.427-3.538 7.13-3.413-1.953L6.197 7.15 2.649 0l-.862.427L5.36 7.63 0 10.7v2.854l9.602-5.492 9.62 5.544v-2.91l-5.36-3.069z"
        />
        <path
            fill="#fff"
            d="M9.612 10.418L0 15.973v2.893l9.602-5.55 9.62 5.605V16.03l-9.61-5.613z"
        />
        <path
            fill="#fff"
            d="M9.612 15.945L0 21.292 9.602 27l9.62-5.708-9.61-5.347zM88.572 16.127c.52 0 .928-.125 1.223-.374.295-.25.443-.588.443-1.015v-.031c0-.458-.148-.807-.443-1.046-.295-.239-.708-.359-1.238-.359h-1.366v2.825h1.38zm-4.293-5.285h4.503c.66 0 1.26.084 1.801.25.54.168 1 .41 1.381.73.38.318.673.711.878 1.177.205.465.308.992.308 1.579v.03c0 .628-.113 1.18-.338 1.655a3.388 3.388 0 01-.938 1.2 4.05 4.05 0 01-1.434.729 6.433 6.433 0 01-1.808.243H87.19v3.037h-2.912v-10.63zM79.843 21.472H82.8v-10.63h-2.957v10.63zM73.37 21.472c-.761 0-1.447-.097-2.056-.29a3.975 3.975 0 01-1.552-.895c-.427-.403-.755-.913-.983-1.53-.228-.617-.342-1.343-.342-2.18v-5.735h3.015v5.674c0 .775.175 1.349.528 1.721.353.373.826.558 1.42.558.595 0 1.068-.178 1.42-.535.353-.357.53-.913.53-1.668v-5.75h3.014v5.659c0 .867-.12 1.613-.358 2.24-.238.628-.573 1.143-1.004 1.546a4.042 4.042 0 01-1.569.894c-.614.194-1.302.291-2.063.291zM66.958 16.128c0-2.935-2.33-5.286-5.477-5.286-3.146 0-5.507 2.38-5.507 5.315v.03c0 2.934 2.331 5.285 5.478 5.285h5.159l-1.02-1.756a5.246 5.246 0 001.367-3.559v-.029zm-8.085.059v-.03c0-1.475 1.035-2.76 2.58-2.76 1.572 0 2.635 1.3 2.635 2.79v.029c0 1.477-.998 2.745-2.607 2.745-1.558 0-2.608-1.299-2.608-2.774z"
        />
        <path
            fill="#fff"
            d="M45.836 10.842h8.584v2.506h-5.667v1.61h5.132v2.323h-5.132v1.686h5.743v2.505h-8.66v-10.63z"
        />
        <path
            fill="#fff"
            d="M35.908 10.842h8.584v2.506h-5.666v1.61h5.132v2.323h-5.132v1.686h5.742v2.505h-8.66v-10.63z"
        />
        <path
            fill="#fff"
            d="M30.706 19.103c.455 0 .804-.083 1.047-.25.243-.167.365-.408.365-.722v-.03a.824.824 0 00-.35-.699c-.233-.172-.597-.258-1.093-.258h-2.247v1.96h2.278zm-.425-4.024c.455 0 .802-.076 1.04-.228.237-.152.356-.385.356-.698v-.03c0-.294-.111-.52-.334-.676-.222-.157-.556-.236-1.002-.236h-1.913v1.868h1.852zm-4.723-4.237H31c.667 0 1.238.081 1.713.243.475.162.869.4 1.182.714.213.213.38.46.5.744.122.284.182.607.182.972v.03c0 .618-.153 1.116-.46 1.496a2.99 2.99 0 01-1.17.873c.649.213 1.164.52 1.545.919.381.4.571.96.571 1.678v.03c0 .921-.352 1.64-1.055 2.157-.704.516-1.693.774-2.968.774h-5.482v-10.63z"
        />
    </svg>
)

BeequipLogoWhite.displayName = 'BeequipLogoWhite'
