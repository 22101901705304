import React from 'react'

export const FacebookIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <path
            fill="#fff"
            d="M14.5 11.2l.6-3.6h-3.5V5.3c0-1 .5-2 2-2h1.6V.2S13.8 0 12.4 0C9.5 0 7.7 1.7 7.7 4.9v2.8H4.5v3.6h3.2V20h3.9v-8.8h2.9z"
        />
    </svg>
)

FacebookIcon.displayName = 'FacebookIcon'
